import { defineStore } from 'pinia'
import axios from 'axios'

export const useUserStore = defineStore("UserStore", {
    id: 'users',
    state: () => ({
        user: null,
        isLogged: false,
        campaign:0
    }),
    actions: {
        async getUser() {
            return new Promise((resolve, reject) => {
                axios.get('/api/user')
                .then((result) => {
                    //console.log('Result2', result);
                    this.user = result.data;
                    const user = result;
                    resolve(user);
                  })
                  .catch((err) =>  {
                    reject({
                        message: "Грешка при намирането на потребителя!",
                        Error: err
                      })
                  });            
            })
          },
          async loggedIn() {            
            const result = await axios.get('/api')
            //console.log(result);
            if (result.data.login == true)
            {
              this.user = result.data
              return true
            }               
            else
                return false
          },
          signIn(userName, userPass) {
            return new Promise((resolve, reject) => {
                axios.post('/api/login', {
                    username: userName,
                    password: userPass
                  })
                  .then((result) => {
                    this.user = result.data
                    this.data = result.data
                    this.isLogged = true
                    console.log('Result', result)
                    resolve ({
                        isOk: true,
                        message: ""
                      });
                  })
                  .catch((err) =>  {
                    reject({
                        isOk: false,
                        message: "Грешни данни за вход!",
                        Error: err
                      })
                  });
            })              
          },
          signOut(){
            console.log('SignOut');
            this.user = null
            this.data = null
            this.isLogged = false
            axios.get('/api/logout')
            .then((result) => {
              //console.log('Logout', result);
              if (result.data.login == false)
              {
                this.user = null
                this.isLogged = false
              }

            })
            .catch(err => console.error(err))
          }
    },
    getters:{
      UserData: (state) => state.user,
      User: (state) => state.user
    }
});