<template>
    <div>
        <DxResponsiveBox>
        <DxRow />
        <DxRow />
        <DxRow :colspan="4" />
        <DxRow screen="xs sm" />
        <DxCol :ratio="0.5" screen="md lg" />
        <DxCol :ratio="2" />
        <DxCol :ratio="2" screen="md lg" />
        <DxCol :ratio="0.5" screen="md lg" />
        <DxItem>
            <DxLocation :row="0" :col="1" />
            <h5>
                <i>Регистрационна форма</i>
            </h5>
        </DxItem>
        <DxItem>
            <DxLocation :row="1" :col="1" screen="md lg" />
            <DxLocation :row="1" :col="0" screen="xs sm" />
            <div class="box">
                <div class="label">
                    Име
                </div>
                <DxTextBox value-change-event="keyup" styling-mode="filled" placeholder="Име" v-model="formData.name" />
            </div>
            <div class="box">
                <div class="label">
                    Презиме
                </div>
                <DxTextBox v-model="formData.surname" styling-mode="filled" placeholder="Презиме" />
            </div>
            <div class="box">
                <div class="label">
                    Фамилия
                </div>
                <DxTextBox styling-mode="filled" placeholder="Фамилия" value-change-event="keyup"
                    v-model="formData.lastname" />
            </div>
            <div class="box float-parent-element2" style="width: 100%;">
                <div style="width: 30%;">
                    <div class="label" v-if="KLVStore.Kod(40).length > 0">
                        {{ KLVStore.Kod(54)[0].group }} 
                    </div>
                    <DxSelectBox styling-mode="filled" placeholder="ЕГН/ЛНЧ" 
                    v-model="formData.client_egntype"  :data-source="KLVStore.Kod(54)"  display-expr="name" value-expr="value"
                       ></DxSelectBox>
                </div>
                <div style="height:1cm; width:36%;">
                    <div class="label" style="padding-left: 1rem; ">
                        &nbsp;
                    </div>
                    <DxTextBox style="left:5%;  " styling-mode="filled" placeholder="ЕГН / ЛНЧ"
                        v-model="formData.client_egn" />
                </div>
                <div class="label" style="padding-left: 5%;width: 40%;">
                        <div style="padding-left: 1rem; padding-bottom: 2.2%; ">
                            Дата на раждане
                        </div>
                            <DxCalendar class="calendar">
                                
                           </DxCalendar>
                    </div>
            </div>
            <div class="box" style="padding-bottom: .5rem;">
                <div class="label" v-if="KLVStore.Kod(40).length > 0">
                    {{ KLVStore.Kod(40)[0].group }}

                </div>

                <DxSelectBox placeholder="Избери" :data-source="KLVStore.Kod(40)" v-model="formData.client_type"
                    display-expr="name" value-expr="value" />
            </div>
           

        </DxItem>
        <DxItem>
            <DxLocation :row="1" :col="2" screen="md lg" />
            <DxLocation :row="2" :col="0" screen="xs sm" />
            <div class="box">
                <div class="label">
                    E-mail
                </div>
                <DxTextBox styling-mode="filled" placeholder="Е-майл" value-change-event="keyup" v-model="formData.email" />
            </div>
        <div class="box">
                <div class="label">

                </div> Телефон
                <DxTextBox styling-mode="filled" placeholder="Телефон" v-model="formData.mobile" />
            </div>
            <div class="box" style="padding-bottom:.8rem;padding-top: .5rem;"> Парола
                <DxTextBox mode="password" styling-mode="filled" placeholder="Парола">
                </DxTextBox>
            </div>
            <div class="box"> Повтори паролата
                <DxTextBox mode="password" styling-mode="filled" placeholder="Повтори паролата">
                </DxTextBox>
                <DxButton style="color: chocolate;border-radius: 12px;left: 25%;top:40%;" icon="add"
                    @click="() => TogglePopup('buttonTrigger')" text="Добави член на семейство" />
            </div> 
        </DxItem>




        <DxItem>
            <DxLocation :row="2" :col="1" screen="md lg" />
            <DxLocation :row="3" :col="0" screen="xs sm" />
            <br />
            <div class="box">
                <div v-if="formData.name.length > 0">
                    Име: <b>{{ formData.name }}</b>
                </div>
                <div v-if="formData.surname.length > 0">
                    Презиме: <b>{{ formData.surname }}</b>
                </div>
                <div v-if="formData.lastname.length > 0">
                    Фамилия: <b>{{ formData.lastname }}</b>
                </div>
                <div v-if="formData.email.length > 0">
                    E-mail: <b>{{ formData.email }}</b>
                </div>
                <div v-if="formData.client_egn.length > 0">
                    ЕГН: <b>{{ formData.client_egn }}</b>
                </div>
                <div v-if="formData.client_type.length > 0">
                    Вид на клиента: <b>{{ formData.client_type }}</b>
                </div>
                <div v-if="formData.client_type.length > 0">
                    Телефон: <b>{{ formData.mobile }}</b>
                </div>
            </div>
        </DxItem>

    </DxResponsiveBox>

    <DxButton style="color: green;border-radius: 12px;left: 45%;" icon="save" text="Изпрати" />


    <PopupBox class="Popup" v-if="popupTriggers.buttonTrigger" :TogglePopup="() => TogglePopup('buttonTrigger')">
        <DxResponsiveBox class="popup-inner">
            <DxRow />
            <DxRow />
            <DxRow />
            <DxRow />
            <DxRow/>
            <DxCol :ratio="0.2" screen="md lg"/>
            <DxCol :ratio="3"  />
            <DxCol :ratio="0.2" screen="md lg"/>
            
            <DxItem>
                <DxLocation :row="0" :col="1"  />
                <h5>
                    <i style="padding-left: 35%;">Регистрационна форма</i>
                </h5>
            </DxItem>
            <DxItem>
                <DxLocation :row="1" :col="1" screen="md lg" />
                <DxLocation :row="1" :col="0" screen="xs sm" />
                <div class="box">
                    <div class="label">
                        Име
                    </div>
                    <DxTextBox value-change-event="keyup" styling-mode="filled" placeholder="Име" v-model="formData.name" />
                </div>
                <div class="box">
                    <div class="label">
                        Презиме
                    </div>
                    <DxTextBox v-model="formData.surname" styling-mode="filled" placeholder="Презиме" />
                </div>
                <div class="box">
                    <div class="label">
                        Фамилия
                    </div>
                    <DxTextBox styling-mode="filled" placeholder="Фамилия" value-change-event="keyup"
                        v-model="formData.lastname" />
                </div>
                <div class="box float-parent-element2" style="width: 100%;">
                    <div style="width: 30%;">
                        <div class="label" v-if="KLVStore.Kod(40).length > 0">
                          {{ KLVStore.Kod(54)[1].group }}
                          
                        </div>
                        <DxSelectBox styling-mode="filled" placeholder="ЕГН/ЛНЧ"  v-model="formData.client_egntype" 
                        :data-source="KLVStore.Kod(54)"  display-expr="name" value-expr="value"
                          ></DxSelectBox>
                    </div>
                    <div style="height:1cm; width:36%;">
                        <div class="label" style="padding-left: 1rem; ">
                            <br>
                        </div>
                        <DxTextBox style="left:5%;  " styling-mode="filled" placeholder="ЕГН / ЛНЧ"
                            v-model="formData.client_egn" />
                    </div>
                    <div class="label" style="padding-left: 5%;">
                        <div style="padding-left: 1rem; padding-bottom: 2.2%; ">
                            Дата на раждане
                        </div>
                            <DxCalendar class="calendar">
                                
                           </DxCalendar>
                    </div>
                    </div>
                
                    <div class="box" style="padding-bottom: .5rem;">
                        <div class="label" v-if="KLVStore.Kod(40).length > 0">
                            {{ KLVStore.Kod(40)[0].group }}
                        </div>
                        <DxSelectBox placeholder="Избери" :data-source="KLVStore.Kod(40)" v-model="formData.client_type"
                            display-expr="name" value-expr="value" />
                    </div>
            </DxItem>
            <DxItem>
                <DxLocation :row="2" :col="1" screen="md lg" />
                <DxLocation :row="3" :col="0" screen="xs sm" />
                <br />
                <div class="box">
                    <div v-if="formData.name.length > 0">
                        Име: <b>{{ formData.name }}</b>
                    </div>
                    <div v-if="formData.surname.length > 0">
                        Презиме: <b>{{ formData.surname }}</b>
                    </div>
                    <div v-if="formData.lastname.length > 0">
                        Фамилия: <b>{{ formData.lastname }}</b>
                    </div>
                    <div v-if="formData.email.length > 0">
                        E-mail: <b>{{ formData.email }}</b>
                    </div>
                    <div v-if="formData.client_egn.length > 0">
                        ЕГН: <b>{{ formData.client_egn }}</b>
                    </div>
                    <div v-if="formData.client_type.length > 0">
                        Вид на клиента: <b>{{ formData.client_type }}</b>
                    </div>
                    <div v-if="formData.client_type.length > 0">
                        Телефон: <b>{{ formData.mobile }}</b>
                    </div>

                    <DxLocation :row="2" :col="0" :colspan="3" screen="md lg" />
                    <DxLocation :row="1" :col="0" :colspan="0" screen="xs sm" />
                    <div class="box" style="text-align:center;">
                        <DxButton style="color: green;border-radius: 12px;top:0.2cm;" icon="save"
                            @click="() => TogglePopup('buttonTrigger')" text="Изпрати" />

                            <DxButton style="color: red;border-radius: 12px;top:0.2cm;left: 2%;" icon=""
                            @click="() => TogglePopup('buttonTrigger')" text="Отказ" />
                           
                    </div>
                </div>
            </DxItem>

        </DxResponsiveBox>
    </PopupBox>
    </div>
    
</template>

<script>
import DxCalendar from './calendar-box.vue';
import DxTextBox from 'devextreme-vue/text-box'
import DxSelectBox from 'devextreme-vue/select-box'
import { useKlvStore } from '@/stores/klv.js'
import { ref } from 'vue';
import PopupBox from './Popup-module.vue';
import DxButton from 'devextreme-vue/button';
import DxResponsiveBox, { DxCol, DxRow, DxItem, DxLocation } from 'devextreme-vue/responsive-box';

export default {
    components: {
        DxSelectBox,
        DxTextBox,
        DxResponsiveBox,
        DxCol,
        DxRow,
        DxLocation,
        DxItem,
        DxButton,
        PopupBox,
        DxCalendar
    },
    setup() {
        const KLVStore = useKlvStore()
        //KLVStore.List()
        const popupTriggers = ref({
            buttonTrigger: false,
        });
        const TogglePopup = (trigger) => {
            popupTriggers.value[trigger] = !popupTriggers.value[trigger]
        }
        return {
            KLVStore,
            PopupBox,
            popupTriggers,
            TogglePopup
        }
    },
    async beforeMount() {
        await this.KLVStore.GetAttr()
    },
    data() {
        return {
            formData: {
                name: '',
                surname: '',
                lastname: '',
                email: '',
                client_egn: '',
                mobile: '',
                client_type: [],
                family: [],
                password: "",
                passwordFieldType: 'password',
                client_egntype: [],
            }
        };
    },
};
</script>

<style scoped>
.calendar {
    width: 100%;
    padding-left:0%;
  
}
h5 {
    padding: 0;
    margin: .5rem
}

.box {
    padding: 0.2rem .5rem
}

.box .label {
    padding: .3rem 0;
}

.Popup {
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.2);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding-top: 2%;
    padding-left: 18%;
    padding-bottom: 2%;
    padding-right: 18%;
    
   
}

.popup-inner {
    
    justify-content: center;
    background: #FFF;
    padding: 10px;
   
    display: flex;
}

.float-parent-element {

    display: flex;
    width: 100%;
    grid-template-columns: 1fr 1fr;
}

.float-parent-element2 {
    display: flex;
    width: 20%;
    height: 25%;
    grid-template-columns: 1fr 1fr;
}

</style>