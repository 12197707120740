<template>
    <div style="padding:0 2rem">
    <div>
        <div class="row my-3 d-flex justify-content-center">
            <div class="col">
                <div  class="card p-3">
                    <h3 style="text-align: center;">Намери място</h3> 
                    <div class="row">                        
                        <div class="col-sm-12 col-md-6 col-lg">От<calendarBox width="100%"/></div>
                        <div class="col-sm-12 col-md-6 col-lg">До<calendarBox width="100%"/></div>
                        <div class="col-sm-12 col-md-6 col-lg">
                            <div>Период за настаняване</div>
                            <DxSelectBox width="100%" :data-source="Obj.Period"></DxSelectBox>
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg">
                            <br>
                            <div class="flex items-center justify-center gap-x-4">
                                Брой възрастни
                            <DxButton
                                type="default"
                                styling-mode="contained"
                                v-on:click="startCountAdults++">
                                <font-awesome-icon :icon="['fas', 'user-plus']" />
                            </DxButton>
                            {{ startCountAdults }}
                            <DxButton
                                type="default"
                                styling-mode="contained"
                                v-on:click="startCountAdults--"
                                v-if="startCountAdults>=0">
                                <font-awesome-icon :icon="['fas', 'user-minus']" />
                            </DxButton>
                        </div>
                            <!-- <DxSelectBox width="100%" id="1">{{ 0 }}</DxSelectBox> -->
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg">
                           <br>
                            <div class="flex items-center justify-center gap-x-4">
                                Брой деца
                            <DxButton
                                type="default"
                                styling-mode="contained"
                                v-on:click="startCountChild++">
                                <font-awesome-icon :icon="['fas', 'user-plus']" />
                            </DxButton>
                            {{ startCountChild }}
                            <DxButton
                                type="default"
                                styling-mode="contained"
                                v-on:click="startCountChild--"
                                v-if="startCountChild>=0">
                                <font-awesome-icon :icon="['fas', 'user-minus']" />
                            </DxButton>
                        </div>
                            <!-- <DxSelectBox width="100%"></DxSelectBox> -->
                           
                        </div>
                        <div class="col-sm-12 col-md-6 col-lg" style="row:2">
                            <div>&nbsp;</div>
                            <DxButton 
                                text="Търси" 
                                width="100%" 
                                type="default"
                                styling-mode="contained"
                            />                       
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
        <div v-if="bases != null" class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 g-4">
            <div v-for="base in bases" :key="base.c_bid" class="col">
                <vs-card 
                    :id="base.c_bid"
                    :title="`${base.b_name} - ${base.c_bid}`"
                    :image="base.b_image"
                    buttonText="Виж повече"
                    @clicked="GoToDetails"
                />
            </div>
        </div>
    </div>
    <!-- <h3 style="text-align: center;">Намери място</h3>
    <div class="content-block">
      <div class="dx-card responsive-paddings"> 
        
        <DxResponsiveBox>
        <DxRow />
        <DxRow />
        <DxRow />
        <DxRow />
        <DxCol :ratio="3" screen="md lg" />
        <DxCol :ratio="3" />
        <DxCol :ratio="3" screen="md lg" />
        <DxCol :ratio="3" screen="md lg" />
        <DxCol :ratio="3" screen="md lg" />
         <DxItem >
            <DxLocation :row="0" :col="0" />
           От<calendarBox style="width: 90%;"></calendarBox>
        </DxItem>
        <DxItem >
            <DxLocation :row="0" :col="1" />
            До  <calendarBox style="width: 90%;"></calendarBox>
        </DxItem>
        <DxItem>
            <DxLocation :row="0" :col="2" />
            <div>Период за настаняване</div>
            <DxSelectBox class="selectBox" style="width: 90%;"></DxSelectBox>
        </DxItem>
        <DxItem>
            <DxLocation :row="0" :col="3" />
            <DxLocation :row="0" :col="2" :colspan="0" screen="xs sm" />
            <div>Брой лица</div>
            <DxSelectBox class="selectBox" style="margin-right: 5%;"></DxSelectBox>
        </DxItem>
        <DxItem>
            <DxLocation :row="0" :col="4" />
            <div class="box" style="margin-right: 10%;padding-right: 20%;">
            <br>
                <button class="button-15" role="button">Търси</button>

                    </div>
        </DxItem>        
        <DxItem>
            <DxLocation :row="1" :col="0" />
            <div>Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box align-center">

   
            </div>
        
        </DxItem>
        <DxItem>
            <DxLocation :row="1" :col="1" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>
            </div>
        </DxItem>
        
        <DxItem>
            <DxLocation :row="1" :col="2" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>
   
                    </div>
           
        </DxItem>
        <DxItem>
            <DxLocation :row="1" :col="3" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>
   
                    </div>
           
        </DxItem>
        <DxItem>
            <DxLocation :row="1" :col="3" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>
   
            </div>
           
        </DxItem>
        <DxItem>
            <DxLocation :row="2" :col="0" />
            <div style="margin-left: 18%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 8%;">
                <button class="button-15" role="button">Виж повече</button>
   
                    </div>
          
        </DxItem>
        <DxItem>
            <DxLocation :row="2" :col="1" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>
   
                    </div>
          
        </DxItem>
        
        <DxItem>
            <DxLocation :row="2" :col="2" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>
   
                    </div>
           
        </DxItem>
        <DxItem>
            <DxLocation :row="2" :col="3" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>
   
                    </div>
           
        </DxItem>
        <DxItem>
            <DxLocation :row="1" :col="3" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
                <source  srcset="@/Images/borovest.jpg">
                <source  srcset="@/Images/borovest.jpg">
                <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
                </picture>
                <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>   
            </div>           
        </DxItem>
        <DxItem style="padding-left:35% ;">
            <DxLocation :row="1" :col="4" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>
   
                    </div>
            </DxItem>
            <DxItem style="padding-left:35% ;">
            <DxLocation :row="2" :col="4" />
            <div style="margin-left: 25%;">Боровец</div>
            <picture >
            <source  srcset="@/Images/borovest.jpg">
            <source  srcset="@/Images/borovest.jpg">
             <img src="@/Images/borovest.jpg" alt="Borovets" class="booking-picture" >
            </picture>
            <div class="box" style="margin-left: 10%;">
                <button class="button-15" role="button">Виж повече</button>
   
                    </div>
            </DxItem>
     </DxResponsiveBox> 
     
      </div>
    </div> -->
  </div> 
</template>
<script setup>
import calendarBox from '@/components/calendar-box.vue';
import DxSelectBox from 'devextreme-vue/select-box';
import vsCard from '@/components/ui/vs-card.vue'
import useBasesStore from '@/stores/bases.js'
//import DxResponsiveBox, { DxCol, DxLocation, DxRow } from 'devextreme-vue/responsive-box';
//import { DxItem } from 'devextreme-vue/toolbar';
import { DxButton } from 'devextreme-vue/button';
import { useRouter } from 'vue-router';
import { ref, onMounted } from 'vue'
import { storeToRefs } from "pinia";

const router = useRouter()
const store = useBasesStore()
const { bases } = storeToRefs(store);
const { GetBases } = store;
const startCountAdults = ref(0)
const startCountChild = ref(0)

const Obj = ref({
    Id: 0,
    Title: null,
    Erorr: null,
    Period: ['ЛЯТО','ЗИМА']
})
onMounted(async ()=>{
    GetBases()
})
function GoToDetails(id){
    router.push({path: '/details/'+id})
}
</script>

<style>
.col-20, .col-lg-20{
  width: calc(100% / 5);
}
.selectBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
}
.float-parent-element {

display: flex;
width: 100%;
grid-template-columns: 1fr 1fr;
}

.float-parent-element2 {
display: flex;
width: 20%;
height: 25%;
grid-template-columns: 1fr 1fr;
}
.box {
    padding: 0.2rem .5rem
}

.box .label {
    padding: .3rem 0;
}
.align-center {
    text-align: center;
}
.booking-picture{
    width: 160px;
    padding-top: 3%;
   margin-left: 5%;
}
.button {
  transition-duration: 0.4s;
}

.button:hover {
  background-color: #83c4f5; /* Green */
  color: white;
}



/* CSS */
.button-15 {
  background-image: linear-gradient(#42A1EC, #0070C9);
  border: 1px solid #0077CC;
  border-radius: 4px;
  box-sizing: border-box;
  color: #FFFFFF;
  cursor: pointer;
  direction: ltr;
  display: block;
  font-family: "SF Pro Text","SF Pro Icons","AOS Icons","Helvetica Neue",Helvetica,Arial,sans-serif;
  font-size: 17px;
  font-weight: 400;
  letter-spacing: -.022em;
  line-height: 1.47059;
  min-width: 30px;
  overflow: visible;
  padding: 4px 15px;
  text-align: center;
  vertical-align: baseline;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
}

.button-15:disabled {
  cursor: default;
  opacity: .3;
}

.button-15:hover {
  background-image: linear-gradient(#51A9EE, #147BCD);
  border-color: #1482D0;
  text-decoration: none;
}

.button-15:active {
  background-image: linear-gradient(#3D94D9, #0067B9);
  border-color: #006DBC;
  outline: none;
}


</style>
