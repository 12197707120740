import { defineStore } from 'pinia'
import axios from 'axios'
export const useKlvStore = defineStore("KlvStore", {
    id: 'klv',
    state: () => ({
        klv:[]
    }),
    actions: {
      List() {
        this.loading = true
        try {
          axios.get('/api/klv/list')
          .then((response) => {
            this.klv = response.data;
            return response.data;
        }) 
        } catch (error) {
          this.error = error
        } finally {
          this.loading = false
        }
      },
      GetAttr() {
        return new Promise((resolve, reject) => {
          axios.get('/api/klv/list')
          .then((response) => {
            this.klv = response.data;
            resolve(response.data);
          }) 
          .catch(err=>reject(err))
        })

      }
    },
    getters:{
        GetKlv: (state) => state.klv,
        Kod: (state) => {
            return (kod) => state.klv.filter(function (el)
            {
              return el.kod==kod;
            })
        }
        
      }
});
