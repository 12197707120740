<template>
    <div>
        <DxDataGrid :data-source="users">        
        <DxFilterPanel :visible="true"/>
        <DxHeaderFilter :visible="true" />
        <DxFilterRow :visible="true" />
            <DxColumn
                data-field="id"
                :width="140"
                alignment="right"
                caption="ID"              
                >
            </DxColumn>
            <DxColumn
                data-field="user"
                caption="Име"
            />
            <DxColumn
                data-field="type"
                :width="200"
                caption="Тип"
                :customize-text="userTypeStr"
            />
            <DxColumn
                data-field="confirmed"
                :width="200"
                caption="Потвърден"
            />
            <DxColumn
                data-field="finalized"
                :width="200"
                caption="Финализиран"
            />
        </DxDataGrid>
    </div>
</template>

<script>
//import DxDataGrid, { DxColumn, DxFilterRow, DxHeaderFilter, DxFilterPanel, DxPager, DxPaging, DxEditing, DxPopup} from "devextreme-vue/data-grid";
import DxDataGrid, { DxColumn, DxFilterRow, DxHeaderFilter, DxFilterPanel } from 'devextreme-vue/data-grid'
import { useKlvStore } from '@/stores/klv';
export default {
    setup(){
        const store = useKlvStore()
        return { store }
    },
    data(){
        return {
            klv: null
        }
    },
    methods: {
        userTypeStr(data) {
            return this.store.Kod(40)[data.value].name
        }
    },
    async beforeMount(){
        //console.log('---------------------------')
        this.klv = this.store.GetKlv.length>0 ? this.store.GetKlv : await this.store.GetAttr()
        //console.log('Klv:',this.klv)
    },
    components: { DxDataGrid, DxColumn, DxFilterRow, DxHeaderFilter, DxFilterPanel },
    props:['users'],
}
</script>