import { defineStore } from 'pinia'
import axios from 'axios'
export const useBaseStore = defineStore("BaseStore", {
    id: 'Bases',
    isLoggedIn: false,
    state: () => ({
        bases: null,
        currentBase: null,
        shifts:null,
        currentShift: null,
        rooms:null
    }),
    actions: {
      GetBases() {
        return new Promise((resolve, reject) => {
          this.loading = true
          try {
             axios.get('/api/hotel/list')
            .then((response) => {
              this.bases = response.data;
              resolve(response.data);
          }) 
          } 
          catch (error) {
            this.error = error
            reject(error)
          } 
          finally {
            this.loading = false
          }
        })

      },
      GetShifts() {
        return new Promise((resolve, reject) => {
          //http://127.0.0.1:3001/api/shifts/base/2022/2
            axios.get('/api/hotel/shifts/'+this.currentBase.b_year+ '/' +this.currentBase.c_bid)
            .then((response) => {
              const shifts = response.data
              //console.log('Shifts',shifts)
              this.shifts = shifts
              resolve(shifts)
            })
            .catch(err=>reject(err))
        })
      },
      GetRooms(id, accomodation) {
        return new Promise((resolve, reject) => {
          const data = {
            shift: parseInt(id),
            base: this.currentBase.c_bid,
            year: this.currentBase.b_year,
            accomodation: accomodation
          }
            axios.post('/api/hotel/rooms', data)
            .then((response) => {
              const rooms = response.data
              console.log('Rooms',rooms, data)
              this.rooms = rooms
              resolve(rooms)
            })
            .catch(err=>reject(err))
        })
      },
      CurrentBase(id) {
        return new Promise((resolve, reject) => {
          if (this.bases == null)
          {
            this.GetBases()
            .then(() => {
              //console.log(response)
              const base = this.bases.find(x => x.c_bid == id)
              this.currentBase = base
              resolve(base)
            })
            .catch(err=>reject(err))
          }
          else
          {
            console.log('Bases already fetched!')
            const base = this.bases.find(x => x.c_bid == id)
            this.currentBase = base
            resolve(base)
          }
        })
      }
    },
    getters:{
      getBases: (state) => state.bases,
      getShifts: (state) => state.shifts
    }
});
export default useBaseStore