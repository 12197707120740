<template>
    <div class="card h-100">
        <div :class="imgClass" >
            <img :src="`/images/${image}`" :alt="image">
        </div>
        
        <div class="card-body">
            <div class="align-left">
                <h5 v-if="title" class="card-title">{{ props.title }}</h5>
                <h5 v-if="subtitle" class="card-sub-title">{{ title }}</h5>
                <p  v-if="text" class="card-text">{{ title }} {{ id }}</p>
                <DxButton :text="buttonText" :width="buttonWidth" type="default" styling-mode="contained"
                    @click="GoToDetails(id)" />
            </div>
        </div>
    </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { DxButton } from 'devextreme-vue/button';
const emit = defineEmits(['change', 'delete'])
const imgClass = ref()
const buttonWidth = ref(0)
const props = defineProps({
    id: Number,
    title: String,
    subtitle: String,
    text: String,
    image: String,
    imageClass: String,
    link: Object,
    buttonText: String,
    buttonWidth: Number
})
onMounted(()=>{
    imgClass.value = props.imageClass ? props.imageClass : 'landscape card-img-top'
    buttonWidth.value = props.buttonWidth ? props.buttonWidth : 140
})

function GoToDetails(params) {
    emit('clicked', params)
}
</script>

<style scoped>
.landscape{
    position:relative;
    overflow:hidden;
    padding-bottom:60%;
}
.landscape img{
    position:absolute;
    width: 100%;
  height: 100%;
  object-fit: cover;
}
</style>