<template>
    <div class="p-3">
        <DxSelectBox 
        :data-source="props.shifts" 
        :display-expr="displayShift"
        value-expr="s_id"
        @value-changed="selectShift"
        placeholder="Моля изберете смяна"
        />
    </div>
</template>
<script setup>
    import { DxSelectBox } from 'devextreme-vue';
    
    const emit = defineEmits(['selectShift'])
    const props = defineProps({
        shifts: Object
    })    
    const displayShift = (item) => {
        return item && item.Shift + ': ' + item.Start + ' - ' + item.End;
    }
    const selectShift = (e) => {
        //console.log(e.value)
        emit('selectShift', e.value)
    }
</script>
<style scoped>

</style>