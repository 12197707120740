<template>
    <div>
      <h2 class="content-block">Регистрация</h2>
      <div class="content-block">
        <div class="dx-card responsive-paddings">
            <registration-form></registration-form>
        </div>
      </div>
    </div>
  </template>

  <script>
  import RegistrationForm from '../components/registration-form.vue'
  export default {
    components: { RegistrationForm }
  }
  </script>