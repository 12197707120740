<template>
    <div>
        <div v-if="result.success" class="my-2">
            <div class="my-success p-3 mb-3">
                {{ result.message }}
            </div>            
            <div class="align-center">
                <DxButton @click="result = { error: false, success:false } " text="Назад" type="default" styling-mode="contained"/> 
                <DxButton @click="goHome" text="Продължи" class="ms-3" type="default" styling-mode="contained"/> 
            </div>
        </div>
        <div v-else-if="result.error" class="my-2">
            <div class="my-danger p-3 mb-3">
                {{ result.message }}
            </div>  
            <div class="align-center">
                <DxButton @click="result = { error: false, success:false }" text="Назад" type="default" styling-mode="contained"/> 
                <DxButton @click="goHome" text="Продължи" class="ms-3" type="default" styling-mode="contained"/> 
            </div>
        </div>
        <div v-else class="row">
            <div class="col-12 pb-3">
                Моля въведете Вашето ЕГН и вашата пронадлежност:
            </div>
            <div class="col-12 pb-3" v-if="UserData !== null">
                <b>Електронна поща:</b> {{ UserData.user }}
            </div>
            <div class="col-12 pb-1" v-if="Kod(54).length > 0">
                {{ Kod(54)[1].group }}
            </div>
            <div class="col-12 pb-3">
                <DxSelectBox 
                styling-mode="filled" 
                placeholder="ЕГН/ЛНЧ" 
                v-model="user.ssn_type" 
                :data-source="Kod(54)" 
                display-expr="name" 
                value-expr="value" 
                />
            </div>
            <div class="col-12 pb-1">
                ЕГН/ЛНЧ
            </div>
            <div class="col-12 pb-3">
                <DxTextBox 
                value-change-event="keyup" 
                styling-mode="filled" 
                placeholder="ЕГН/ЛНЧ" 
                v-model="user.ssn" 
                />
            </div>
            <div class="col-12 pb-1" v-if="Kod(40).length > 0">
                {{ Kod(40)[0].group }}
            </div>
            <div class="col-12 pb-3">
                <DxSelectBox 
                placeholder="Избери" 
                :data-source="Kod(40)" 
                v-model="user.type"
                display-expr="name" 
                value-expr="value" 
                />
            </div>
            <div class="col-12 pb-1" v-if="Kod(40).length > 0">
                Дата на раждане
            </div>
            <div class="col-12 pb-3">
                <DxDateBox 
                v-model="user.birth"
                display-format="dd.MM.yyyy"
                />
            </div>
            
            {{ UserData }}
            <hr>
            {{ user }}
            <div class="col-12 pt-2 align-center" >
                <DxButton @click="sendData" text="Изпрати" /> 
            </div>
        </div>

    </div>
</template>

<script setup>
//import CalendarBox from '../calendar-box.vue';
import DxTextBox from 'devextreme-vue/text-box'
import DxSelectBox from 'devextreme-vue/select-box'
import DxDateBox from 'devextreme-vue/date-box';
import { useKlvStore } from '@/stores/klv.js'
import { useUserStore } from '@/stores/user.js'
import { ref, onMounted } from 'vue';
import DxButton from 'devextreme-vue/button';
import { storeToRefs } from 'pinia';
import axios from 'axios';
import { useRouter } from 'vue-router'
const user = ref({
    ssn: '7804126581',
    ssn_type: 1,
    birth: '1978-04-12',
    type: 1
})
const result = ref({
    error: false,
    success: false,
    message: ''
})
const Router = useRouter()
const userStore = useUserStore()
//const { getUser } = userStore
const { UserData } = storeToRefs(userStore)

const klvStore = useKlvStore()
const { GetAttr } = klvStore
const { Kod } = storeToRefs(klvStore)

onMounted(async ()=>{
    await GetAttr();
   // await getUser();
    [UserData.value.firstName, UserData.value.middleName, UserData.value.lastName] = UserData.value.name.split(' ');
})

const sendData = () => {
    const params = {
        ssn: user.value.ssn,
        type: parseInt(user.value.type),
        email: UserData.value.user,
        firstName: UserData.value.firstName,
        middleName: UserData.value.middleName,
        lastName: UserData.value.lastName,
        birth: user.value.birth,
        phone:UserData.value.phone
    }
    let success = false
    console.log(params)
    axios.post('/api/registration/finish', params)
    .then(response => {
        console.log(response)
        success = response.data.success ? true : false
        result.value = {
            error: !success,
            success: success,
            message: response.data.message
        }
    })
    .catch(err=>{
        result.value = {
            error: true,
            success:false,
            message: err.message
        }
    })
}
const goHome = () =>{
    Router.push({name: "home"})
}
</script>

<style>
.my-danger {
    background-color:brown;
    border-radius: 2px;
    color:white;
}
.my-success {
    background-color:greenyellow;
    border-radius: 2px;
    color:darkolivegreen;
}
</style>