<template>
    <div class="widget-container">
        <div v-if="Obj.Erorr==null" class="container bg-white border pt-3" style="width: 60%;">
            <DxGallery id="gallery" :data-source="imagesTest" :loop="true" :slideshow-delay="4000"
                :show-nav-buttons="true" :show-indicator="true" :height="400" :stretch-images="true" />

            <div class="row">
                <div class="col align-center py-3">
                    <h3>
                        Current Hotel ID: {{ Route.params.id }}
                    </h3>
                </div>
                <div class="col py-3 align-right">
                    <DxButton text="Резервирай" width="220px" type="default" styling-mode="contained"
                        @click="startReservation" />
                </div>
            </div>
            <h3 class="align-center p-3 mt-1">{{ Obj.Title }}</h3>
            <div>

                <!-- <div>
                    <font-awesome-icon :icon="['fas', 'check']" style="color: #6dee7c;" />


                    <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-5 g-4">
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'shower']" class="icons" /> Душ</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'person-swimming']" class="icons" /> Басейн</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'fan']" class="icons" /> Климатик</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'bath']" class="icons" /> Вана</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'umbrella-beach']" class="icons" /> Плаж</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'dumbbell']" class="icons" /> Фитнес зала</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'vault']" class="icons" /> Сейф</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'utensils']" class="icons" /> Ресторант</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'tv']" class="icons" /> Телевизор</div> <br>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'couch']" class="icons" /> Диван</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'ban-smoking']" class="icons" /> Стая за непушачи</div>
                        <div class="col">
                            <font-awesome-icon :icon="['fas', 'wifi']" class="icons" /> Wi-fi</div>
                    </div>
                </div> -->



                <hr>
                <div class="row">
                    <div class="col">
                        <div class="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
                            <div v-for="row in Obj.Comfort" :key="row.icon" class="col">
                                <font-awesome-icon :icon="['fas', row.icon]" class="icons" /> {{ row.title }}
                            </div>
                        </div>
                    </div>
                    <div class="col"></div>
                </div>


              
                <!-- <div v-for="rooms in currentBase.value.c_status"></div>
 
    
         
        <div v-else class="text-center p-3 bg-danger">{{ Obj.Erorr }}</div> -->
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onBeforeMount } from 'vue'
import DxGallery from 'devextreme-vue/gallery';
import { useRoute, useRouter } from 'vue-router'
import { useBaseStore } from '@/stores/bases.js'
import { DxButton } from 'devextreme-vue/button';
import { storeToRefs } from "pinia";
import { getComfort } from '@/services/baseDetails.js'

const store = useBaseStore()
const images = ref([])
const imagesTest = ref([])
const Route = useRoute()
const Router = useRouter()
const { CurrentBase } = store
const { currentBase } = storeToRefs(store)

const Obj = ref({
    Id: 0,
    Title: null,
    Erorr: null,
    Comfort: null 
})
onBeforeMount(() => {
    CurrentBase(Route.params.id)
    .then(()=>{
        Obj.value = {
            Title: currentBase.value.b_name,
            Id: currentBase.value.c_bid
        }   
        return getComfort(currentBase.value.c_bid)     
    })
    .then((comfort)=>{
        Obj.value.Comfort=comfort
    })
    .catch(()=>{
        Obj.value.Erorr = "Избраната база не съществува!"
    })

    images.value = [
        '/images/14_1.jpg',
        '/images/14_2.jpg',
        '/images/14_3.jpg',
]
    for(let i=0; i<3; i++){ //Цикъл за въртене на слайдовете със снимки
        let str = '/images/'+Route.params.id + '_' + (i+1) + '.jpg'
        imagesTest.value.push(str)
    }
})
const startReservation = () => {
    Router.push({name: 'hotelReservation', params: {id: Route.params.id}})
}

</script>
<style scoped>
.icons{
    color: #3c6ab9;
    padding-left: 2%;
}
</style>