<template>
    <div>
        <div v-if="boxVisible">
            <div class="box">
                <DxTextBox
                :show-clear-button="true"
                mode="text"
                placeholder="Моля въведете Вашият код"
                styling-mode="outlined"
                value=""
                value-change-event="keyup"
                v-model="localCode" 
                />
            </div>

            <div class="box">
                <DxButton
                :width="140"
                text="Потвърди"
                type="default"
                styling-mode="contained"
                @click="onSubmit()"
                />
                <br><br>
                <div v-if="result != null" :class="style">
                    {{ result }}
                </div>
            </div>
        </div>
        <div v-else >
            <div :class="style">
                {{ result }}
            </div>
            <br>
            <DxButton
                :width="200"
                text="ВХОД В СИСТЕМАТА"
                type="default"
                styling-mode="contained"
                @click="Enter()"
                />
                <br>
        </div>
    </div>
</template>

<script setup>
import { defineProps, ref, toRefs, onMounted } from 'vue'
import {useRouter} from 'vue-router'
import DxButton from "devextreme-vue/button";
import DxTextBox from "devextreme-vue/text-box";
import axios from 'axios'

const props = defineProps({
    code: String,
    email: String
})
const router = useRouter()
const localCode = ref('')
const result = ref(null)
const boxVisible = ref(false)
const style = ref('danger')
const { code, email } = toRefs(props);
onMounted(()=>{
    if (code.value.length>8)
    {
        Confirm(code.value)
        .then(response => {
            boxVisible.value = response.confirmed ? false : true
        })
        .catch(err => {
            result.value= err.message
            boxVisible.value = true
        })
    }
    else
        boxVisible.value = true
})


const Enter = () => {
    router.push({ name: 'login-form' })
}
const onSubmit = async () => {
    await Confirm(localCode.value)
}
const Confirm = async (code) => {
    return new Promise((resolve, reject) => {
        axios.get(`/api/registration/confirm/${email.value}/${code}`)
        .then((response) =>{
            console.log(response)
            if (response.data.confirmed) {
                result.value = 'Успешно валидиране'
                style.value = 'success'
                boxVisible.value = false
            }
            else 
            {
                result.value = response
                style.value = 'danger'
            }
            resolve(response.data)
        })
        .catch(err => {
            result.value = err
            style.value = 'danger'
            reject(err)
        })
    })

}
</script>

<style scoped>
    .box{
        padding:.5rem 0
    }
    .success {
        text-align:center;
        font-weight: bold;
        padding:1rem;
        background-color:rgb(229, 250, 212);
        color:#0f3203;
    }
    .danger {
        text-align:center;
        font-weight: bold;
        background-color:rgb(250, 212, 212);
        padding:1rem;
        color:#660000;
    }
</style>