<template>
  <div>
    <h2 class="content-block">Profile</h2>
    <div class="container">
      <div class="row">
        <div class="col">
          <div class="row" v-if="User">{{ User.name }}</div>         
        </div>
        <div class="col">sasd</div>
      </div>
    </div>
    
  </div>
</template>

<script setup>
import { useUserStore } from "@/stores/user";
import { storeToRefs } from 'pinia';
const userStore = useUserStore()
const { User } = storeToRefs(userStore)
</script>

<style lang="scss">
.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}
</style>
