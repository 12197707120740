<template>
    <div>
        <users-grid v-if="data!=null" :users="data"></users-grid>
    </div>
</template>

<script setup> //composition api
import axios from 'axios'
import { onMounted, ref } from 'vue'
import usersGrid from '@/components/users/users-grid.vue';

const data = ref(null)
onMounted(async()=>{
    const response = await axios.get('/api/user/list')
    data.value = response.data
})

</script>

