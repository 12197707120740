<template>
    <div>
        <div class="row">
            <div class="col-6">
                <shifts-box :shifts="Obj.shifts" @selectShift="onShiftSelect"/>
                <rooms-grid :rooms="Obj.rooms" :location="Obj.locationType"/>
            </div>
            <div class="col-6">                
               
            </div>
        </div>        
    </div>
</template>

<script setup>
    import { useRoute } from 'vue-router'
    import ShiftsBox from '../../components/reservation/shifts-box.vue'
    import RoomsGrid from '../../components/reservation/rooms-grid.vue'
    import { ref, onBeforeMount } from 'vue';
    import useBaseStore from '@/stores/bases.js'
    import { storeToRefs } from 'pinia'
    const route = useRoute()
    const store = useBaseStore()
    const { GetShifts, CurrentBase, GetRooms } = store;
    const { shifts, rooms } = storeToRefs(store)
    const Obj = ref({
        shifts:null,
        locationType: 0,
        error: {
            message: null,
            err: null
        }
    })
    const onShiftSelect = (shiftId) => {
        //console.log('Selected Shift: ',shiftId)
        GetRooms(shiftId, 2)
        .then(()=>{
            Obj.value.rooms = rooms
        })
        .catch(err => Obj.value.error = {
            message: "Възникна грешка !!!", 
            err: err
        })
    }
    onBeforeMount(()=>{
        CurrentBase(route.params.id)
        .then((response)=>{
            //console.log(response)
            Obj.value.locationType=response.location_type
            //console.log(Obj.value.locationType)
            return GetShifts()
        })    
        .then(()=>{
            Obj.value.shifts = shifts.value
        })
        .catch(err => Obj.value.error = {
            message: "Възникна грешка !!!", 
            err: err
        })
    })
</script>