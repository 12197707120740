<template>
    <div class="p-3">
        <DxDataGrid 
        :data-source="props.rooms" 
        key-expr="RoomID"
        @selection-changed="onSelectionChange"
        :paging="false"
        height="68vh"
        :show-border="true"
        >
            <DxColumn 
            caption="Вид" 
            data-field="Type"
            />
            <DxColumn 
            caption="№ на помещение" 
            data-field="RoomNumber"
            />
            <DxColumn 
            caption="Легла" 
            dataField="Beds"           
            />
            <DxColumn 
            caption="Гости" 
            dataField="Guests"           
            />
            <!-- <template #cellTemplate="{ data }">
                <span>{{ data.value }} {{ renderRoomsField() }}</span>
                
            </template> -->
            
        </DxDataGrid>
        
    </div>
</template>
<script setup>
    import { onMounted } from 'vue';
    import { DxDataGrid, DxColumn } from 'devextreme-vue/data-grid';
    //import { storeToRefs } from 'pinia';
    const emit = defineEmits(['selectRoom'])
    const props = defineProps({
        rooms: Object,
        location: Number
    })
    const onSelectionChange = (e) => {
        emit('selectRoom', e.value)
    }

    onMounted(async () => {        
    
    })
</script>
<style scoped>

</style>