import axios from 'axios'

const getComfort = (id) => {
    return new Promise((resolve, reject) => {
      axios.get('/api/hotel/comfort/'+id)
      .then((response) => {
        const comfort = response.data
        const Comfort=[]
        comfort.forEach((value)=>{
            switch(value){
                case 'басейн' :
                    Comfort.push({icon: 'person-swimming', title: value})
                    break;
                case 'Минерален басейн' :
                    Comfort.push({icon: 'person-swimming', title: value})
                    break;                    
                case 'Сейф' :
                    Comfort.push({icon: 'vault', title: value})
                    break;                    
                case 'боулинг' :
                    Comfort.push({icon: 'person-swimming', title: value})
                    break;                    
                case 'СПА' :
                    Comfort.push({icon: 'person-swimming', title: value})
                    break;                    
                case 'джакузи' :
                    Comfort.push({icon: 'person-swimming', title: value})
                    break;   
                default:
                    Comfort.push({icon: 'check'})         
            }
        })
        resolve(Comfort)
      })
      .catch(err=>reject(err))
    })
  }

export { getComfort }