import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';
import { createApp }  from "vue";
import router from "./router";
import { createPinia } from "pinia";
import appInfo from "./app-info";
import App from "./App";
import JsonViewer from "vue3-json-viewer";
import "vue3-json-viewer/dist/index.css";
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import { library, dom } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

library.add(fas, fab, far)
dom.watch() // Наблюдава Dom-a за font-awesome в атрибута class на елемента
const app = createApp(App);
app.use(createPinia());
app.use(router);
app.use(JsonViewer);
app.component('font-awesome-icon', FontAwesomeIcon)
app.config.globalProperties.$appInfo = appInfo;
app.mount("#app");


/*
npm install --save bootstrap
npm install --save @popperjs/core
*/