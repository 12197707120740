export default [{
    text: "Начало",
    path: "/home",
    icon: "home"
  },
  {
    text: "Потребителски профил",
    path: "/profile",
    icon: "fa fa-user"
  },
  {
    text: "Регистрация",
    path: "/registration",
    icon: "fa fa-key"
  },
  {
    text: "Запази място",
    path: "/booking-index",
    icon: 'fa fa-location-dot'
  },
  {
    text: "Администриране",
    icon: "folder",
    items:[
      {
        text: "Потребители",
        path: "/users"
      }
    ]
}];
