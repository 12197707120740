import { useUserStore } from "./stores/user";
import { createRouter, createWebHistory } from "vue-router";
import Home from "./views/home-page";
import RegistrationPage from '@/views/registration-page.vue'
import Profile from "./views/profile-page";
import Tasks from "./views/tasks-page";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import mediumLayout from './layouts/medium-card.vue'
import userList from "./views/users/user-list.vue";
import confirmUser from './views/registration/confirm-user.vue'
import booking from "./views/users/booking-index.vue";
import details from "./views/users/details-hotel.vue";
import hotelReservation from "./views/users/reservation-page.vue"
import userUIC from "./components/users/user-uic.vue"
function loadView(view) {
  return () => import (/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/registration",
      name: "registration",
      meta: {
        requiresAuth: false,
        layout: defaultLayout
      },
      component: RegistrationPage
    },
    {
      path: "/booking-index",
      name: "booking",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: booking
    },
    {
      path: "/details/:id",
      name: "details",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: details
    },
    {
      path: "/reservation/hotel/:id",
      name: "hotelReservation",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: hotelReservation
    },
    {
      path: "/confirm-user/:email/:code",
      name: "confirmUser",
      meta: {
        requiresAuth: false,
        layout: mediumLayout
      },
      component: confirmUser,
      props: true
    },
    {
      path: "/finish-registration",
      name: "finish-registration",
      meta: {
        requiresAuth: false,
        layout: mediumLayout,
        title: "Приключване на регистрацията:"
      },
      component: userUIC,
      props: true
    },
    {
      path: "/profile",
      name: "profile",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Profile
    },
    {
      path: "/tasks",
      name: "tasks",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Tasks
    },
    {
      path: "/users",
      name: "users",
      meta: {
        requiresAuth: true,
        layout: defaultLayout
      },
      component: userList
    }, 
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Вход"
      },
      component: loadView("login-form")
    },
    {
      path: "/registration-form",
      name: "registration-form",
      meta: {
        requiresAuth: false,
        layout: mediumLayout,
        title: "Регистрация"
      },
      component: loadView("registration/register-form")
    },
    {
      path: "/logout",
      name: "logout",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Излязохте успешно"
      },
      component: loadView("logout-page")
    },
    {
      path: "/reset-password",
      name: "reset-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Reset Password",
        description: "Please enter the email address that you used to register, and we will send you a link to reset your password via Email."
      },
      component: loadView("reset-password-form")
    },
    {
      path: "/change-password/:recoveryCode",
      name: "change-password",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Change Password"
      },
      component: loadView("change-password-form")
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/recovery",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    }
  ],
  history: createWebHistory()
});

router.beforeEach((to, from, next) => {
  const userStore = useUserStore();
  userStore.loggedIn()
  .then((isLoggedIn) => {
    //console.log(to.name);
    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (!isLoggedIn) {
        console.log('login false');
          next({
            name: "login-form",
            query: { redirect: to.fullPath }
          });
      } 
      else
      {
        if (userStore.UserData.uic == null || userStore.UserData.uic.length < 4)
          next({ name: "finish-registration" });
        else
          next();
      } 

    } 
    else if (to.name === "login-form" && isLoggedIn) {
      console.log('Is Logged:', isLoggedIn);
      next({ name: "home" });
    }
    else {
      next();
    }
  })
  .catch(() => {
    next({
      name: "login-form",
      query: { redirect: to.fullPath }
    });
  })
});

export default router;
