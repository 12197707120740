import axios from 'axios'
export default {
  _user: axios.get('/api/user'),
  loggedIn() {
    return !!this._user;
  },

  async logIn(userName, userPass) {
    try {
      let response = {}
      let UserData = await axios.post('/api/login', {
        username: userName,
        password: userPass
      })
      let User = UserData.data

      console.log('User:' + JSON.stringify(User));
      if (!User.login)
      {
        response = {
          isOk: true,
          data: this._user
        }
        this._user = User;
      }
      else{
        response = {
          isOk: false,
          data: null
        }
        this._user = null;
      }
      return response
    }
    catch {
      return {
        isOk: false,
        message: "Authentication failed"
      };
    }
  },

  async logOut() {
    this._user = null;
  },

  async getUser() {
    try {
      // Send request

      return {
        isOk: true,
        data: this._user
      };
    }
    catch {
      return {
        isOk: false
      };
    }
  },

  async resetPassword(email) {
    try {
      // Send request
      console.log(email);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to reset password"
      };
    }
  },

  async changePassword(email, recoveryCode) {
    try {
      // Send request
      console.log(email, recoveryCode);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to change password"
      }
    }
  },

  async createAccount(email, password) {
    try {
      // Send request
      console.log(email, password);

      return {
        isOk: true
      };
    }
    catch {
      return {
        isOk: false,
        message: "Failed to create account"
      };
    }
  }
};
